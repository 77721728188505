import React, { useEffect, useState } from "react";
import styles from "./CreateCampaign.module.css";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../../../components/SelectComponent";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import Stepper from "awesome-react-stepper";
import { Spin } from "antd";
import PageHeaderTwo from "../../../../components/PageHeader/PageHeaderTwo";

function CreateCampaign() {
  const navigate = useNavigate();
  const [rules, setRules] = useState([""]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [levelAdded, setLevelAdded] = useState(false);
  const [taskAdded, setTaskAdded] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [rolesType, setRolesType] = useState([]);
  const [errors, setErrors] = useState({});

  const initialCampaignDetails = {
    campaignTitle: "",
    NoOfLevels: 0,
    Duration: {
      startDate: "",
      endDate: "",
    },
    roleId: "",
    country: "",
    state: "",
    rule: [],
    maxTejCoins: "",
    maxLakshmiCoins: "",
    termsAndConditions: "",
    status: "Draft",
    levelsData: [],
  };
  const [campaignDetails, setCampaignDetails] = useState(
    initialCampaignDetails
  );
  const levels = Array.from({ length: 20 }, (_, index) => ({
    value: index + 1,
    label: `${index + 1}`,
  }));
  const handleLevelSelect = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      NoOfLevels: selectedOption.value,
    }));
  };

  const [formData, setFormData] = useState({
    NoOfLevels: "",
  });

  useEffect(() => {
    // Fetch task types from the API
    const fetchTaskTypes = async () => {
      try {
        const response = await userRequest.get("/master/getMasterTaskType");
        setTaskTypes(response?.data?.data);
      } catch (error) {
        console.error("Error fetching task types:", error);
      }
    };
    fetchTaskTypes();
  }, []);

  useEffect(() => {
    // Fetch task types from the API
    const fetchRoles = async () => {
      try {
        const response = await userRequest.get("/userRoles/getAllUserRoles");
        setRolesType(response?.data?.data);
      } catch (error) {
        console.error("Error fetching task types:", error);
      }
    };
    fetchRoles();
  }, []);

  const handleAddRule = () => {
    setRules([...rules, ""]);
    setCampaignDetails((prevDetails) => ({
      ...prevDetails,
      rule: [...prevDetails.rule, ""],
    }));
  };

  const handleRemoveRule = (index) => {
    const newRules = [...rules];
    newRules.splice(index, 1);
    setRules(newRules);
    setCampaignDetails((prevDetails) => {
      const newRuleList = [...prevDetails.rule];
      newRuleList.splice(index, 1);
      return {
        ...prevDetails,
        rule: newRuleList,
      };
    });
  };

  const handleRuleChange = (index, event) => {
    const newRules = [...rules];
    newRules[index] = event.target.value;
    setRules(newRules);
    setCampaignDetails((prevDetails) => {
      const newRuleList = [...prevDetails.rule];
      newRuleList[index] = event.target.value;
      return {
        ...prevDetails,
        rule: newRuleList,
      };
    });
  };

  const handleAddLevel = () => {
    setLevelAdded(true);
    setCampaignDetails((prevState) => ({
      ...prevState,
      levelsData: [
        ...prevState.levelsData,
        {
          levelName: "",
          levelDescription: "",
          totalTasks: 0,
          maxCoins: 0,
          coins: "",
          tasksData: [],
        },
      ],
      NoOfLevels: prevState.levelsData.length + 1,
    }));

    setTimeout(() => {
      setLevelAdded(false);
    }, 1000);
  };

  const handleRemoveLevel = (index) => {
    setCampaignDetails((prevState) => {
      const updatedLevelsData = prevState.levelsData.filter(
        (_, i) => i !== index
      );
      return {
        ...prevState,
        levelsData: updatedLevelsData,
        NoOfLevels: updatedLevelsData.length,
      };
    });
  };

  const handleAddTask = (levelIndex) => {
    setTaskAdded(true);
    setCampaignDetails((prevState) => {
      const newLevelsData = [...prevState.levelsData];
      newLevelsData[levelIndex].totalTasks =
        newLevelsData[levelIndex].tasksData.length + 1;
      newLevelsData[levelIndex].tasksData.push({
        taskName: "",
        taskDescription: "",
        coinValue: 0,
        taskCount: 1,
        operationId: "",
        taskType: "",
        numberOfDays: 0,
      });
      return {
        ...prevState,
        levelsData: newLevelsData,
      };
    });
    setTimeout(() => {
      setTaskAdded(false);
    }, 1000);
  };

  const handleRemoveTask = (levelIndex, taskIndex) => {
    setCampaignDetails((prevState) => {
      const newLevelsData = [...prevState.levelsData];
      newLevelsData[levelIndex].tasksData.splice(taskIndex, 1);
      return {
        ...prevState,
        levelsData: newLevelsData,
      };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCampaignDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDurationChange = (event) => {
    const { name, value } = event.target;
    setCampaignDetails((prevState) => ({
      ...prevState,
      Duration: {
        ...prevState.Duration,
        [name]: value,
      },
    }));
  };

  const roleOptions = rolesType.map((role) => ({
    label: role.roleName,
    value: role._id,
  }));

  const coinOptions = [
    { value: "Tej", label: "Tej" },
    { value: "Lakshmi", label: "Lakshmi" },
  ];

  const handleRoleSelect = (option) => {
    setCampaignDetails((prevState) => ({
      ...prevState,
      roleId: option.value,
    }));
  };

  const handleCoinSelect = (option, levelIndex) => {
    setCampaignDetails((prevState) => {
      console.log("Prev state:", prevState);
      const newState = {
        ...prevState,
        levelsData: prevState.levelsData.map((levelDetail, idx) => {
          if (idx === levelIndex) {
            return {
              ...levelDetail,
              coins: option.value,
            };
          }
          return levelDetail;
        }),
      };
      return newState;
    });
  };

  const handleTermsChange = (value) => {
    setTermsAndConditions(value);
    setCampaignDetails((prevDetails) => ({
      ...prevDetails,
      termsAndConditions: value,
    }));
  };

  const handleSave = async () => {
    const {
      campaignTitle,
      NoOfLevels,
      Duration,
      roleId,
      rule,
      termsAndConditions,
      maxTejCoins,
      maxLakshmiCoins,
      levelsData,
    } = campaignDetails;

    let newErrors = {}; // Temporary errors object
    let hasError = false; // Flag to check for any errors

    // Validate main fields
    if (!campaignTitle) {
      newErrors.campaignTitle = "Campaign Title is required";
      hasError = true;
    }
    if (!NoOfLevels) {
      newErrors.NoOfLevels = "Number of Levels is required";
      hasError = true;
    }
    if (!Duration.startDate) {
      newErrors.startDate = "Start Date is required";
      hasError = true;
    }
    if (!Duration.endDate) {
      newErrors.endDate = "End Date is required";
      hasError = true;
    }
    if (!roleId) {
      newErrors.roleId = "Role is required";
      hasError = true;
    }
    if (!selectedCountry) {
      newErrors.country = "Country is required";
      hasError = true;
    }
    if (!selectedState) {
      newErrors.state = "State is required";
      hasError = true;
    }
    if (!rule || rule.length === 0) {
      newErrors.rule = ["Rule is required"];
      hasError = true;
    } else {
      const ruleErrors = rule.map((r, index) =>
        !r ? `Rule ${index + 1} cannot be empty` : ""
      );
      if (ruleErrors.some((err) => err)) {
        newErrors.rule = ruleErrors.filter(Boolean); // Filter out empty strings
        hasError = true;
      }
    }
    if (!maxTejCoins) {
      newErrors.maxTejCoins = "Tej Coins is required";
      hasError = true;
    }
    if (!maxLakshmiCoins) {
      newErrors.maxLakshmiCoins = "Lakshmi Coins is required";
      hasError = true;
    }
    if (!termsAndConditions || termsAndConditions === "<p><br></p>") {
      newErrors.termsAndConditions = "Terms and Conditions is required";
      hasError = true;
    }

    // Validate levels and tasks within levels
    const levelsErrors = levelsData.map((level, levelIndex) => {
      const levelError = { tasks: [] };

      if (!level.levelName || level.levelName.trim() === "") {
        levelError.levelName = "Level name is required";
        hasError = true;
      }
      if (!level.maxCoins || isNaN(level.maxCoins) || level.maxCoins < 0) {
        levelError.maxCoins = "Max coins must be a positive number";
        hasError = true;
      }
      if (!level.coins) {
        levelError.coins = "Coins type is required";
        hasError = true;
      }

      // Validate each task within the level
      level.tasksData.forEach((task) => {
        const taskError = {};
        if (!task.taskDescription || task.taskDescription.trim() === "") {
          taskError.taskDescription = "Task description is required";
          hasError = true;
        }
        if (!task.coinValue || isNaN(task.coinValue) || task.coinValue <= 0) {
          taskError.coinValue = "Coin value must be a positive number";
          hasError = true;
        }
        if (!task.taskName) {
          taskError.taskName = "task type is required";
          hasError = true;
        }
        levelError.tasks.push(taskError);
      });

      return levelError;
    });

    newErrors.levels = levelsErrors;

    // Validate start and end date
    const { startDate, endDate } = campaignDetails.Duration;
    if (new Date(endDate) <= new Date(startDate)) {
      newErrors.endDate = "End date must be greater than start date";
      hasError = true;
    }

    // If there are errors, set them and return early
    if (hasError) {
      setErrors(newErrors); // Set errors if any are found
      return;
    }

    console.log(errors);
    // If no errors, proceed with save request
    const updatedCampaignDetails = {
      ...campaignDetails,
      countryId: selectedCountry?.value, // Save the country ID or name as required
      stateId: selectedState?.value, // Save the state ID or name as required
    };

    try {
      let response = await userRequest.post(
        "/admin/svarna/addCampaign",
        updatedCampaignDetails
      );
      if (response?.status === 201) {
        navigate("/svarnamanagement");
        toast.success("Saved successfully!");
        setCampaignDetails(initialCampaignDetails);
        setErrors({}); // Clear errors after successful save
      }
    } catch (error) {
      console.log("Error saving campaign:", error);
      let err = error?.response?.data?.error;
      toast.error(err);
    }
  };

  const handleLevelInputChange = (index, field, value) => {
    setCampaignDetails((prevState) => {
      const newLevel = [...prevState.levelsData];
      if (newLevel[index]) {
        if (field === "levelName") {
          newLevel[index].levelName = value;
          newLevel[index].levelNumber = index + 1;
          newLevel[index].levelDescription = value;
        } else if (field === "totalTasks") {
          newLevel[index].totalTasks = parseInt(value) || 0;
        } else if (field === "maxCoins") {
          newLevel[index].maxCoins = parseInt(value) || 0;
        } else if (field === "coins") {
          newLevel[index].coins = value;
        }
        return {
          ...prevState,
          levelsData: newLevel,
        };
      } else {
        // If newLevel[index] is undefined, return prevState as it is
        return prevState;
      }
    });
  };

  const handleTaskInputChange = (levelIndex, taskIndex, field, option) => {
    setCampaignDetails((prevState) => {
      const newLevelDetails = [...prevState.levelsData];
      const taskDetails =
        newLevelDetails[levelIndex].tasksData[taskIndex] || {};
      if (field === "taskName") {
        taskDetails.taskName = option.label;
        taskDetails.taskDescription = option.value;
        taskDetails.operationId = option.operationId;
        taskDetails.taskType = option.taskType;
      } else if (field === "coinValue") {
        taskDetails.coinValue = option;
      } else if (field === "taskDescription") {
        taskDetails.taskDescription = option.value;
      } else if (field === "taskCount") {
        taskDetails.taskCount = option;
      } else if (field === "numberOfDays") {
        taskDetails.numberOfDays = option;
      }
      newLevelDetails[levelIndex].tasksData[taskIndex] = taskDetails;
      return {
        ...prevState,
        levelsData: newLevelDetails,
      };
    });
  };

  const activeStep = campaignDetails?.levelsData?.length;

  useEffect(() => {
    fetchCountry();
  }, []);

  const fetchCountry = async () => {
    try {
      let response = await userRequest.get("/master/getMasterCountry");
      if (response?.status === 200) {
        let data = response?.data?.data;
        const options = data?.map((item) => ({
          label: item?.country,
          value: item?._id,
        }));
        setCountryData(options);
      }
    } catch (error) {
      console.log("Error country:", error);
      let err = error?.response?.data?.error;
      toast.error(err);
    }
  };
  useEffect(() => {
    fetchState();
  }, []);
  const fetchState = async (countryId) => {
    console.log("countryId", countryId);
    if (!countryId) {
      console.error("countryId is undefined");
      setStateData([{ label: "-", value: "-" }]); // Show dash if no country is selected
      return;
    }
    try {
      let response = await userRequest.get(
        `/master/getMasterState?countryId=${countryId}`
      );
      if (response.status === 200) {
        let data = response?.data?.data;
        const options = data?.map((item) => ({
          label: item?.state,
          value: item?._id,
        }));
        setStateData(options);
      }
    } catch (error) {
      console.log("Error state:", error);
      let err = error?.response?.data?.error;
      toast.error(err);
    }
  };

  const handleCountrySelect = (selectedCountry) => {
    setSelectedCountry(selectedCountry);
    fetchState(selectedCountry?.value); // Fetch states based on selected country
  };
  const handleStateSelect = (selectedState) => {
    setSelectedState(selectedState);
  };

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={[
          { label: "Svarna Management", path: "/svarnamanagement" },
          {
            label: "Create Campaign",
            path: "#",
          },
        ]}
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={() => navigate("/svarnamanagement")}
        onSaveClick={handleSave}
      />

      <div className={styles.contentContainer}>
        <div className={styles.section}>
          <p>Campaign Details</p>
          {/* </div> */}
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Campaign Title</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="campaignTitle"
                  value={campaignDetails.campaignTitle}
                  onChange={handleInputChange}
                  placeholder="Enter"
                />
                {errors.campaignTitle && (
                  <div className={styles.errorMessage}>
                    {errors.campaignTitle}
                  </div>
                )}
              </div>
            </div>
            {/* <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Number of level</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={levels}
                  onSelect={handleLevelSelect}
                  // initialSelectedOption={formData.NoOfLevels}
                />
              </div>
            </div> */}
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Duration</p>
              </div>

              <div className={styles.fieldInputTwo}>
                <div className={styles.dateInput}>
                  <input
                    type="date"
                    name="startDate"
                    onChange={handleDurationChange}
                    placeholder="Enter"
                  />
                  {errors.startDate && (
                    <div className={styles.errorMessage}>
                      {errors.startDate}
                    </div>
                  )}
                </div>
                <div className={styles.dateInput}>
                  <input
                    type="date"
                    name="endDate"
                    onChange={handleDurationChange}
                    placeholder="Enter"
                  />
                  {errors.endDate && (
                    <div className={styles.errorMessage}>{errors.endDate}</div>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Role</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={roleOptions}
                  onSelect={handleRoleSelect}
                />
                {errors.roleId && (
                  <div className={styles.errorMessage}>{errors.roleId}</div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Country</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={countryData}
                  onSelect={handleCountrySelect}
                />
                {errors.country && (
                  <div className={styles.errorMessage}>{errors.country}</div>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>State</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={stateData}
                  onSelect={handleStateSelect}
                />
                {errors.state && (
                  <div className={styles.errorMessage}>{errors.state}</div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Max Lakshmi Coins</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="maxLakshmiCoins"
                  value={campaignDetails.maxLakshmiCoins}
                  onChange={handleInputChange}
                  placeholder="Enter"
                />
                {errors.maxLakshmiCoins && (
                  <div className={styles.errorMessage}>
                    {errors.maxLakshmiCoins}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Max Tej Coins</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="maxTejCoins"
                  value={campaignDetails.maxTejCoins}
                  onChange={handleInputChange}
                  placeholder="Enter"
                />
                {errors.maxTejCoins && (
                  <div className={styles.errorMessage}>
                    {errors.maxTejCoins}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Add Rule </p>
              </div>
              <div className={`${styles.fieldInput} ${styles.fieldInputRule}`}>
                {rules.map((rule, index) => (
                  <div key={index}>
                    <div className={styles.rule}>
                      <textarea
                        value={rule}
                        onChange={(e) => handleRuleChange(index, e)}
                        placeholder="Enter"
                      />
                      {rules.length > 1 && (
                        <button
                          onClick={() => handleRemoveRule(index)}
                        ></button>
                      )}
                    </div>
                    {errors.rule?.[index] && (
                      <div className={styles.errorMessage}>
                        {errors.rule[index]}
                      </div>
                    )}
                    {index === rules.length - 1 && (
                      <button onClick={handleAddRule}>Add More</button>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Add Terms & Conditions</p>
              </div>
              <div className={styles.fieldInput}>
                <RichTextEditor
                  value={termsAndConditions}
                  onChange={handleTermsChange}
                />
                {errors.termsAndConditions && (
                  <div className={styles.errorMessage}>
                    {errors.termsAndConditions}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.horizontalRow}></div>
        <div className={styles.levelDetailsMain}>
          <p className={styles.levelDetailsHeading}>Level Details</p>
          <button onClick={handleAddLevel}>Add More</button>
        </div>
        <Spin spinning={levelAdded} size="small">
          <Stepper
            defaultActiveStep={activeStep}
            strokeColor="#f68b21"
            fillStroke="#f68b21"
            activeColor="#f68b21"
            showProgressBar="true"
            activeProgressBorder="2px solid #f68b21"
            submitBtn={false}
            continueBtn={<button className={styles.stepperBtn}>Next</button>}
            backBtn={<button className={styles.stepperBtn}>Back</button>}
            // onSubmit={(step) => alert(`Thank you!!! Final Step -> ${step}`)}
          >
            {campaignDetails.levelsData.map((level, levelIndex) => (
              <div key={levelIndex} className={styles.stepperSubDiv}>
                <button
                  className={styles.deleteItem}
                  onClick={() => handleRemoveLevel(levelIndex)}
                ></button>
                <div className={styles.section}>
                  <div className={styles.fieldContainer}>
                    <div className={styles.feildLeft}>
                      <div className={styles.fieldLabel}>
                        <p>Level {levelIndex + 1}</p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.fieldContainer}>
                    <div className={styles.feildLeft}>
                      <div className={styles.fieldLabel}>
                        <p>Name of Level</p>
                      </div>
                      <div className={styles.fieldInput}>
                        <input
                          type="text"
                          value={
                            campaignDetails.levelsData[levelIndex]?.levelName
                          }
                          onChange={(e) =>
                            handleLevelInputChange(
                              levelIndex,
                              "levelName",
                              e.target.value
                            )
                          }
                          placeholder="Enter"
                        />
                        {errors.levels?.[levelIndex]?.levelName && (
                          <div className={styles.errorMessage}>
                            {errors.levels[levelIndex].levelName}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className={styles.feildRight}>
                      <div className={styles.fieldLabel}>
                        <p>Number of Task</p>
                      </div>
                      <div className={styles.fieldInput}>
                        <input
                          type="text"
                          value={levelDetails[levelIndex].numberOfTasks}
                          onChange={(e) =>
                            handleNumberOfTasksChange(levelIndex, e)
                          }
                          placeholder="Enter"
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className={styles.fieldContainer}>
                    <div className={styles.feildLeft}>
                      <div className={styles.fieldLabel}>
                        <p>Max Coins</p>
                      </div>
                      <div className={styles.fieldInput}>
                        <input
                          type="text"
                          value={
                            campaignDetails.levelsData[levelIndex]?.maxCoins
                          }
                          onChange={(e) =>
                            handleLevelInputChange(
                              levelIndex,
                              "maxCoins",
                              e.target.value
                            )
                          }
                          placeholder="Enter"
                        />
                        {errors.levels?.[levelIndex]?.maxCoins && (
                          <div className={styles.errorMessage}>
                            {errors.levels[levelIndex].maxCoins}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.feildRight}>
                      <div className={styles.fieldLabel}>
                        <p>Select Coins</p>
                      </div>
                      <div className={styles.fieldInput}>
                        <SelectComponent
                          options={coinOptions}
                          onSelect={(option) =>
                            handleCoinSelect(option, levelIndex)
                          }
                        />
                        {errors.levels?.[levelIndex]?.coins && (
                          <div className={styles.errorMessage}>
                            {errors.levels[levelIndex].coins}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.section}>
                  <div className={styles.taskDetailsMain}>
                    <p>Task Details</p>
                    <button
                      className={styles.taskAdd}
                      onClick={() => handleAddTask(levelIndex)}
                      disabled={taskAdded}
                    >
                      Add Task
                    </button>
                  </div>

                  {level.tasksData.map((task, taskIndex) => (
                    <div
                      className={`${styles.fieldContainer} ${styles.fieldContainerTwo}`}
                    >
                      <div className={styles.deleteDiv}>
                        <button
                          className={styles.deleteItemTwo}
                          onClick={() =>
                            handleRemoveTask(levelIndex, taskIndex)
                          }
                        ></button>
                      </div>
                      <div className={styles.feildLeft}>
                        <div className={styles.fieldLabel}>
                          <p>Coin Value</p>
                        </div>
                        <div className={styles.fieldInput}>
                          <input
                            // style={{ background: "white" }}
                            type="text"
                            value={
                              campaignDetails.levelsData[levelIndex]?.tasksData[
                                taskIndex
                              ]?.coinValue
                            }
                            onChange={(e) =>
                              handleTaskInputChange(
                                levelIndex,
                                taskIndex,
                                "coinValue",
                                e.target.value
                              )
                            }
                            placeholder="Enter"
                          />
                          {errors.levels?.[levelIndex]?.tasks?.[taskIndex]
                            ?.coinValue && (
                            <div className={styles.errorMessage}>
                              {
                                errors.levels[levelIndex].tasks[taskIndex]
                                  .coinValue
                              }
                            </div>
                          )}
                        </div>

                        <div className={styles.fieldLabel}>
                          {/* <p>Task {taskIndex + 1}</p> */}
                          <p>Task Description</p>
                        </div>
                        <div className={styles.fieldInput}>
                          <textarea
                            // style={{ background: "white" }}
                            value={
                              campaignDetails.levelsData[levelIndex]?.tasksData[
                                taskIndex
                              ]?.taskDescription
                            }
                            onChange={(e) =>
                              handleTaskInputChange(
                                levelIndex,
                                taskIndex,
                                "taskDescription",
                                e.target.value
                              )
                            }
                            placeholder="Enter"
                          />
                          {errors.levels?.[levelIndex]?.tasks?.[taskIndex]
                            ?.taskDescription && (
                            <div className={styles.errorMessage}>
                              {
                                errors.levels[levelIndex].tasks[taskIndex]
                                  .taskDescription
                              }
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.feildRight}>
                        <div className={styles.fieldLabel}>
                          <p>Task Type</p>
                        </div>
                        <div className={styles.fieldInput}>
                          <SelectComponent
                            // options={taskPointOptions}
                            options={taskTypes.map((task) => ({
                              label: task.title,
                              value: task.description,
                              operationId: task._id,
                              taskType: task.taskType,
                            }))}
                            onSelect={(option) =>
                              handleTaskInputChange(
                                levelIndex,
                                taskIndex,
                                "taskName",
                                option
                              )
                            }
                            // white
                          />
                          {errors.levels?.[levelIndex]?.tasks?.[taskIndex]
                            ?.taskName && (
                            <div className={styles.errorMessage}>
                              {
                                errors.levels[levelIndex].tasks[taskIndex]
                                  .taskName
                              }
                            </div>
                          )}
                        </div>

                        {campaignDetails?.levelsData[levelIndex]?.tasksData[
                          taskIndex
                        ]?.taskType === "Continuous" && (
                          <>
                            <div className={styles.fieldLabel}>
                              <p>Number Of Days</p>
                            </div>
                            <div className={styles.fieldInput}>
                              <input
                                // style={{ background: "white" }}
                                type="text"
                                value={
                                  campaignDetails.levelsData[levelIndex]
                                    ?.tasksData[taskIndex]?.numberOfDays
                                }
                                onChange={(e) =>
                                  handleTaskInputChange(
                                    levelIndex,
                                    taskIndex,
                                    "numberOfDays",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter"
                              />
                            </div>
                          </>
                        )}
                        {campaignDetails?.levelsData[levelIndex]?.tasksData[
                          taskIndex
                        ]?.taskType === "Multiple" && (
                          <>
                            <div className={styles.fieldLabel}>
                              <p>Task Count</p>
                            </div>
                            <div className={styles.fieldInput}>
                              <input
                                // style={{ background: "white" }}
                                type="text"
                                value={
                                  campaignDetails.levelsData[levelIndex]
                                    ?.tasksData[taskIndex]?.taskCount
                                }
                                onChange={(e) =>
                                  handleTaskInputChange(
                                    levelIndex,
                                    taskIndex,
                                    "taskCount",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Stepper>
        </Spin>
      </div>
    </div>
  );
}

export default CreateCampaign;
