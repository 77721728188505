import React, { useEffect, useState } from "react";
import styles from "./Details.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { userRequest } from "../../../requestMethod";
import defaultProfile from "../../../assets/images/profile.png";
import experienceIcon from "../../../assets/images/experienceIcon.png";
import languageIcon from "../../../assets/images/languageIcon.png";
import experienceIconTwo from "../../../assets/images/experienceIconTwo.png";
import addressIcon from "../../../assets/images/addressIcon.png";
import radioIcon from "../../../assets/images/radioIcon.png";
import { Spin } from "antd";
import { formatDate, parseAndFormatDate } from "../../../utils/DateFormat";

function BookingDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [bookingData, setBookingData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchBookingDetails = async () => {
    try {
      setLoading(true);
      const response = await userRequest.get(
        `/poojaManagement/getAdminIndividualPoojaBooking/${id}`
      );
      setBookingData(response.data.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBookingDetails();
  }, [id]);

  const panditLanguages = [
    bookingData?.panditId?.languages?.motherTongue,
    ...(bookingData?.panditId?.languages?.otherLanguage || []),
  ].filter(Boolean);

  // const getLanguages = (languages) => [
  //     languages?.motherTongue?.language,
  //     ...(languages?.otherLanguage?.map(lang => lang.language) || []),
  //   ].filter(Boolean).join(", ");

  const getLanguages = (languages) => {
    const allLanguages = [
      languages?.motherTongue?.language,
      ...(languages?.otherLanguage?.map((lang) => lang.language) || []),
    ].filter(Boolean);

    // Select only the first two languages
    const firstTwoLanguages = allLanguages.slice(0, 1);
    // Join the selected languages into a string
    return firstTwoLanguages.join(", ");
  };

  const {
    dakshinaAtHome = 0,
    poojaMaterials = 0,
    flowerAndFruits = 0,
  } = bookingData?.pooja?.dakshinaDetails || {};
  
  const total = dakshinaAtHome + poojaMaterials + flowerAndFruits;
  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p>
            <span onClick={() => navigate("/poojabookingmanagement")}>
              Pooja Booking
            </span>{" "}
            <span className={styles.activePageStyle}>&gt; Booking Details</span>
          </p>
        </div>
      </div>
      <Spin spinning={loading} size="small">
        <div className={styles.contentContainer}>
          <div className={styles.panditMain}>
            <p>Pandit</p>
            <div className={styles.panditDetailBox}>
              <div className={styles.profileImage}>
                <img
                  src={bookingData?.panditId?.profilePic ?? defaultProfile}
                  alt=""
                />
              </div>
              <div className={styles.profileDetails}>
                <p>{`${bookingData?.panditId?.firstName} ${bookingData?.panditId?.lastName}`}</p>
                <hr />
                <div className={styles.profileBasicDetails}>
                  <div className={styles.profileBasicDetailsSingle}>
                    <img src={languageIcon} alt="" />
                    <p>
                      {panditLanguages.length > 0
                        ? panditLanguages.join(", ")
                        : "Not Available"}
                    </p>
                  </div>
                  <div className={styles.profileBasicDetailsSingle}>
                    <img src={experienceIcon} alt="" />
                    <p>10 years</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className={styles.panditMain}>
            <p>Other Pandit</p>
            <div className={styles.otherPanditMain}>
              {bookingData?.supportingPandits?.length > 0 ? (
                bookingData?.supportingPandits?.map((item) => (
                  <div className={styles.panditDetailBox}>
                    <div className={styles.profileImage}>
                      <img
                        src={item.panditId?.profilePic ?? defaultProfile}
                        alt=""
                      />
                    </div>
                    <div className={styles.profileDetails}>
                      <p>
                        {item.panditId?.firstName} {item.panditId?.lastName}
                      </p>
                      <hr />
                      <div className={styles.profileBasicDetails}>
                        <div className={styles.profileBasicDetailsSingle}>
                          <img src={languageIcon} alt="" />
                          <p>
                            {getLanguages(item.panditId?.languages) ||
                              "Not Available"}
                          </p>
                        </div>
                        <div className={styles.profileBasicDetailsSingle}>
                          <img src={experienceIcon} alt="" />
                          <p>10 years</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>Not Available</p>
              )}
            </div>
          </div>
          <div className={styles.poojaDetailsContainer}>
            <div className={styles.poojaDetailsMain}>
              <p>Pooja Details</p>
              <div className={styles.poojaDetails}>
                <div className={styles.poojaDetailsLeft}>
                  <div className={styles.singlePoojaDetail}>
                    <p>Pooja Category</p>
                    <p>{bookingData?.pooja?.poojaCategory?.category}</p>
                  </div>
                  <div className={styles.singlePoojaDetail}>
                    <p>Pooja Type</p>
                    <div className={styles.singleRowPooja}>
                      <img src={defaultProfile} alt="" />
                      <p>{bookingData?.pooja?.poojaName ?? "Not Available"}</p>
                    </div>
                  </div>
                  <div className={styles.singlePoojaDetail}>
                    <div className={styles.singleRowPoojaTwo}>
                      <img src={experienceIconTwo} alt="" />
                      <p>Pooja Date & Time</p>
                    </div>
                    <p className={styles.pMarginLeft}>
                      {new Date(bookingData?.dateTime).toLocaleString("en-US", {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      })}
                    </p>
                  </div>
                  <div className={styles.singlePoojaDetail}>
                    <div className={styles.singleRowPoojaTwo}>
                      <img src={addressIcon} alt="" />
                      <p>Address</p>
                    </div>
                    <p className={`${styles.pMarginLeft} ${styles.pBold}`}>
                      {bookingData?.place}
                    </p>
                    <p className={`${styles.pMarginLeft}`}>
                      {bookingData?.address?.address1},
                      {bookingData?.address?.address2},
                      {bookingData?.address?.city},
                      {bookingData?.address?.district},
                      {bookingData?.address?.state},
                      {bookingData?.address?.country},
                      {bookingData?.address?.pincode}
                    </p>
                  </div>
                </div>
                <div className={styles.poojaDetailsRight}>
                  <div className={styles.singlePoojaDetail}>
                    <p>Language</p>
                    <p>
                      {bookingData?.pooja?.poojaLanguage?.language ??
                        "Not Available"}
                    </p>
                  </div>
                  <div className={styles.singlePoojaDetail}>
                    <p>Devotee Details</p>
                    <div className={styles.devoteeList}>
                      {bookingData?.devotees?.length > 0 ? (
                        bookingData?.devotees?.map((item) => (
                          <p>
                            {item?.devoteeId?.firstName}{" "}
                            {item?.devoteeId?.lastName}
                          </p>
                        ))
                      ) : (
                        <p>Not Available</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.poojaPackageMain}>
              <p>Pooja Package Details</p>
              <div className={styles.dakshinType}>
                <div className={styles.dakshinTypeLeft}>
                  <img src={radioIcon} alt="" />
                  <p>Dakshin</p>
                </div>
                <div className={styles.dakshinTypeRight}>
                  <p>INR {total || 0}</p>
                </div>
              </div>
              <div className={styles.dakshinPricingMain}>
                <div className={styles.singleDakshinPricing}>
                  <p>Dakshin Price:</p>
                  <p>
                    {bookingData?.pooja?.dakshinaDetails?.dakshinaAtHome || 0}
                  </p>
                </div>
                <hr />
                <div className={styles.singleDakshinPricing}>
                  <p>Pooja Materials:</p>
                  <p>{bookingData?.pooja?.dakshinaDetails?.poojaMaterials}</p>
                </div>
                <hr />
                <div className={styles.singleDakshinPricing}>
                  <p>Flowers & Fruits:</p>
                  <p>{bookingData?.pooja?.dakshinaDetails?.flowerAndFruits}</p>
                </div>
                <hr />
                <div className={styles.singleDakshinPricing}>
                  <p>Total:</p>
                  <p> {total || 0}</p>
                </div>
                <hr />
                <div className={styles.singleDakshinPricing}>
                  <p>Advance Amount:</p>
                  <p>
                    {bookingData?.pooja?.dakshinaDetails?.poojaMode === "online"
                      ? ` ${bookingData?.pooja?.dakshinaDetails?.dakshinaAtHome}`
                      : 0}
                  </p>
                </div>
                <hr />
                <div className={styles.singleDakshinPricing}>
                  <p>Taxes & Charges:</p>
                  <p> {bookingData?.pooja?.dakshinaDetails?.tax ?? 0}</p>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default BookingDetails;
